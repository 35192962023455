@import '../../assets/styles/includes/vars';
@import '../../assets/styles/includes/mixins';

.settings {
    width: 100%;
    background-color: var(--white);
    position: relative;
}

.settingsAside {
    min-width: 220px;
    flex-shrink: 0;
    background-color: var(--blueLight);
    box-shadow: 0 3px 6px rgba(var(--black-rgb), .15);
    position: relative;
}

.settingsTab {
    padding: 5px;
    font-size: 15px;
}

.settingsTabActive {
    font-weight: 700;
    color: var(--secondary);
}

.settingsWrap {
    flex-grow: 1;
}

.settingCompany {
    padding: 30px 40px;
}

.settingsCompanyWrap {
    height: 600px;
}

.settingsDetails {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 50vw;
    width: 100%;
    height: 100%;
}

.settingsConfTab {
    padding: 10px 15px;
    border-bottom: 1px solid var(--greyLight);
}

.settingsConfTabActive {
    font-weight: 700;
    border-width: 3px;
    border-color: var(--secondary);
}

.settingsLeft {
    max-width: 500px;
    width: 100%;
    flex-shrink: 0;
    background-color: var(--white);
    border-right: 1px solid var(--greyLight);
    display: flex;
    flex-direction: column;
}

.settingsFlex {
    display: flex;
}

.settingsHeader {
    padding: 30px 20px;
    border-bottom: 1px solid var(--greyLight);
}

.settingsTitleWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settingsTitleBtn {
    flex-shrink: 0;
}

.settingsTrackers {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.settingsTracker {
    & + & {
        margin-left: 10px;
    }
}

.settingsInfoContent {
    flex-grow: 1;
    overflow: hidden;
}

.settingsInfoContentHeader {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--greyLight);
}

.settingsFilter {
    min-width: 100px;
    flex-shrink: 0;
}

.settingsSearch {
    width: 100%;

    input::placeholder {
        font-weight: 700;
    }
}

.settingsRight {
    flex-grow: 1;
}

.settingsRightContent {
    padding: 40px;
}

.settingsRightBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 20px;
}

.settingsTrackingInfo {
    margin-top: 20px;
}
