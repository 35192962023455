@import "/src/assets/styles/includes/vars";
@import "/src/assets/styles/includes/margin";
@import "/src/assets/styles/includes/mixins";
@import "/src/assets/styles/includes/fonts";
@import "variables/global";

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 15px;
}

body,
html {
  margin: 0;
}

body {
  font-family: "Roboto", sans-serif;
  color: var(--primary);
  font-weight: 400;
  line-height: 1.3;
  background-color: var(--white);
}

a {
  text-decoration: none;
  color: inherit;
  transition: all 0.15s;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button,
input,
textarea {
  background-color: unset;
  border: none;
  font-family: inherit;
  outline: none;
  font-size: inherit;
  color: inherit;
}

button {
  cursor: pointer;
  padding: 0;
  user-select: none;
}

.container {
  max-width: 1640px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
}

.app {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.app-inner {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: #bdbdbd;
  }
}

.main {
  flex-grow: 1;
  display: flex;
}

.mapboxgl-popup {
  max-width: unset !important;
  color: #262B40;
  pointer-events: none;
}

.mapboxgl-popup-content {
  font-family: "Roboto", sans-serif;
  padding: 0;
  background-color: transparent;
}

.marker-speed {
  margin-left: 15px;
  padding-left: 15px;
  position: relative;
  font-weight: 700;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 25px;
    background-color: #D5D5D5;
  }
}

.mapboxgl-popup-close-button,
.mapboxgl-popup-tip {
  display: none;
}

.marker-wrapper {
  border-radius: 10px;
  background-color: #FFFFFF;
  padding: 8px 12px 8px 8px;
  white-space: nowrap;
  min-width: 300px;
  border: 2px dashed #24D5AA;
}

.marker-wrapper-detail {
  border-radius: 5px;
  background-color: #F5F7FB;
  border: 1px solid #D9DDE5;
  padding: 10px 15px;
  white-space: nowrap;
}

.marker-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.marker-bottom {
  display: flex;
  font-size: 15px;
}


.marker-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 8px;
}

.marker-header-icon {
  width: 40px;
  height: 40px;
  background-color: #F6F8FA;
  border-radius: 5px;
  background-image: url("../../../public/assets/mapIcon1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  flex-shrink: 0;
}

.marker-header-item-value {
  font-size: 15px;
  font-weight: 700;
  color: #262B40;
}

.marker-header-item-name {
  font-size: 10px;
  color: #AAB0BC;
}

.marker-header-item {
  width: 50%;
}

.marker-item-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #24D5AA;
  margin-left: 12px;
}

.marker-item {
  border-radius: 5px;
  background-color: #F6F8FA;
  padding: 8px;
  min-width: 80px;
}

.marker-item-value {
  font-size: 15px;
  font-weight: 700;
  color: #262B40;
}

.marker-item-name {
  font-size: 10px;
}

.marker-item-box {
  display: flex;
  align-items: center;
}

.marker-content {
  display: flex;
  gap: 8px;
  margin-bottom: 12px;
}

.marker-footer {
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 1.3;
}

.marker-footer-btn {
  margin-left: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #F6F7FA;
  background-image: url("../../../public/assets/mapArrow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 13px 10px;
}

.marker-footer-icon {
  width: 20px;
  height: 20px;
  background-image: url("../../../public/assets/mapFooterIcon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.marker-footer-address {
  font-size: 10px;
}

.marker-footer-time {
  font-size: 10px;
  color: #AAB0BC;
}